import React from 'react';
import {Link} from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import styles from './index.module.css';

const countries = {
  unitedStates: {
    name: 'United States',
    cities: {
      sf: {
        name: 'San Francisco Bay Area',
      },
      nyc: {
        name: 'New York City',
      },
      chicago: {
        name: 'Chicago',
      },
      grandRapids: {
        name: 'Grand Rapids',
      },
      miami: {
        name: 'Miami',
      },
      detroit: {
        name: 'Detroit',
      },
      la: {
        name: 'Los Angeles',
      },
      sanDiego: {
        name: 'San Diego',
      },
      seattle: {
        name: 'Seattle',
      },
      hawaii: {
        name: 'Hawaii',
      },
    },
  },
  canada: {
    name: 'Canada',
    cities: {
      toronto: {
        name: 'Toronto',
      },
      montreal: {
        name: 'Montreal',
      },
    },
  },
  mexico: {
    name: 'Mexico',
    cities: {
      mexicoCity: {
        name: 'Mexico City',
      },
    },
  },
  peru: {
    name: 'Peru',
    cities: {
      cusco: {
        name: 'Cusco',
      },
    },
  },
  colombia: {
    name: 'Colombia',
    cities: {
      medellin: {
        name: 'Medellín',
      },
    },
  },
  japan: {
    name: 'Japan',
    url: '/travel/japan',
    cities: {
      tokyo: {
        name: 'Tokyo',
      },
      kyoto: {
        name: 'Kyoto',
      },
      hakone: {
        name: 'Hakone',
      },
    },
  },
  spain: {
    name: 'Spain',
    cities: {
      madrid: {
        name: 'Madrid',
      },
      barcelona: {
        name: 'Barcelona',
      },
    },
  },
  germany: {
    name: 'Germany',
    cities: {
      berlin: {
        name: 'Berlin',
      },
      dusseldorf: {
        name: 'Dusseldorf',
      },
      cologne: {
        name: 'Cologne',
      },
      munich: {
        name: 'Munich',
      },
      nuremberg: {
        name: 'Nuremberg',
      },
    },
    recommendations: [
      {
        place: 'Rheinturm Doner Bros',
      },
    ],
  },
  italy: {
    name: 'Italy',
    cities: {
      rome: {
        name: 'Rome',
      },
    },
  },
  belgium: {
    name: 'Belgium',
    cities: {
      brussels: {
        name: 'Brussels',
      },
    },
  },
  netherlands: {
    name: 'Netherlands',
    cities: {
      amsterdam: {
        name: 'Amsterdam',
      },
    },
  },
  france: {
    name: 'France',
    cities: {
      paris: {
        name: 'Paris',
      },
    },
  },
};
const TravelPage = () => (
  <Layout>
    <SEO title="Travel" />
    <h2>Travel</h2>
    <p>
        Here are some places I&#39;ve been and things I recommend doing or seeing.
        This isn&#39;t meant to be a &quot;Top 10 Things To Do In X&quot; sort of list,
        just stuff I happen to have first-hand experience with. It&#39;s also a
        not-so-subtle way of showing off the cool places I&#39;ve been.
    </p>
    <p>
      <i>If it&#39;s not a link, I haven&#39;t written about it yet.</i>
    </p>
    <div className={styles.locationList}>
      {Object.entries(countries).map(([key, country]) => (
        <div key={key} className={styles.country}>
          {!country.url && <h3>{country.name}</h3>}
          {country.url && <Link to={country.url}><h3>{country.name}</h3></Link>}
          {Object.entries(country.cities).map(([key, city]) => (
            <div key={key}>
              {city.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  </Layout>
);

export default TravelPage;
